const formatAddress = (
  address:
    | {
        line1?: string | null
        line2?: string | null
        city?: string | null
        stateOrProvinceAbbrev?: string | null
        postalCode?: string | null
      }
    | null
    | undefined
) => {
  if (!address) return '\u2014'

  let result = address.line1 ? address.line1 : ''

  if (address.line2) {
    if (result) result += ' '
    result += address.line2
  }

  if (address.city) {
    if (result) result += ', '
    result += address.city
  }

  if (address.stateOrProvinceAbbrev) {
    if (result) result += ', '
    result += address.stateOrProvinceAbbrev
  }

  if (address.postalCode) {
    if (result) result += ' '
    result += address.postalCode
  }

  return result ? result : '\u2014'
}

export { formatAddress }
